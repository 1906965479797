<template>
  <div>
    <h2>老年人能力评估表 - B.2 基础运动能力评估表</h2>
    
    <div v-for="(question, index) in questions" :key="index" class="question-section">
      <h3>{{ question.title }}</h3>
      <div v-for="(option, idx) in question.options" :key="idx" class="option">
        <label>
          <input 
            type="radio" 
            :name="'question' + index" 
            :value="option.score" 
            v-model="question.selectedScore"
          >
          {{ option.text }}
        </label>
      </div>
    </div>
    
    <div class="total-score">
      <h3>总计得分: {{ totalScore }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          title: "B.2.1 床上体位转移：卧床翻身及坐起躺下",
          selectedScore: 4,
          options: [
            { text: "独立完成，不需要协助", score: 4 },
            { text: "在他人指导或提示下完成", score: 3 },
            { text: "需要他人协助，但以自身完成为主", score: 2 },
            { text: "主要依靠他人协助，自身能给予配合", score: 1 },
            { text: "完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
        {
          title: "B.2.2 床椅转移：从坐位到站位，再从站位到坐位的转换过程",
          selectedScore: 4,
          options: [
            { text: "独立完成，不需要协助", score: 4 },
            { text: "在他人指导或提示下完成", score: 3 },
            { text: "需要他人协助，但以自身完成为主", score: 2 },
            { text: "主要依靠他人协助，自身能给予配合", score: 1 },
            { text: "完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
        {
          title: "B.2.3 平地行走：双脚交互的方式在地面行动，总是一只脚在前 注：包括他人辅助和使用辅助具的步行",
          selectedScore: 4,
          options: [
            { text: "独立平地步行50m左右，不需要协助，无摔倒风险", score: 4 },
            { text: "能平地步行50m左右，存在摔倒风险，需要他人监护或指导，或使用拐杖、助行器等辅助工具", score: 3 },
            { text: "在步行时需要他人少量扶持协助", score: 2 },
            { text: "在步行时需要他人大量扶持协助", score: 1 },
            { text: "完全不能步行", score: 0 },
          ],
        },
        {
          title: "B.2.4 上下楼梯：双脚交替完成楼梯台阶连续的上下移动",
          selectedScore: 3,
          options: [
            { text: "可独立上下楼梯（连续上下10个-15个台阶），不需要协助", score: 3 },
            { text: "在他人指导或提示下完成", score: 2 },
            { text: "需要他人协助，但以自身完成为主", score: 1 },
            { text: "主要依靠他人协助，自身能给予配合；或者完全依赖他人协助，且不能给予配合", score: 0 },
          ],
        },
      ],
    };
  },
  computed: {
    basicMobilityScore: {
      get() { return this.$store.state.home_older.basicMobilityScore },
      set(val) { this.$store.commit('home_older/updateBasicMobilityScore', val) }
    },
    totalScore() {
      this.basicMobilityScore = this.questions.map(question => question.selectedScore);
      return this.questions.reduce((sum, question) => sum + question.selectedScore, 0);
    },
  },
};
</script>

<style scoped>
.question-section {
  margin-bottom: 20px;
}

.option {
  margin-left: 20px;
}

.total-score {
  margin-top: 30px;
  font-weight: bold;
}
</style>
